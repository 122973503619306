<template>
  <div class="mb-10 sm:mb-20 max-sm:px-psec max-2xl:px-pcon">
    <div id="tt-container" class="relative flex bg-gradient-to-b from-stone-100 rounded-2xl overflow-hidden min-h-[1400px] mx-auto max-w-page">
      <iframe
              id="tt-ibe"
              name="Booking engine"
              data-cookieconsent="preferences"
              allowfullscreen
              aria-label="Booking frame"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              class="absolute inset-0 w-full h-full"
              title="Pauschalreisen"
              :src="srcUrl">&nbsp;
      </iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  ibe?: 'package' | 'hotel' | 'flight',
  params?: string,
}
const props = withDefaults(defineProps<Props>(), {
  ibe: 'package',
  ibeStep: 'region',
  params: undefined,
})

const app = useAppConfig()


const ibeUrlMap = {
  // 'package': 'https://pauschalreise.schmetterling.de/search/search?taid=142035&ddate=2024-07-10&rdate=2024-08-24&srtReg=15&srtHot=5&adult=2',
  // 'package': 'https://pauschalreise.schmetterling.de/',
  'package': app.isDevMode ? '/alibepackage/' : 'https://pauschalreise.schmetterling.de/',
  // 'hotel': 'https://eigenanreise.schmetterling.de/',
  'hotel': app.isDevMode ? '/alibehotel/' : 'https://eigenanreise.schmetterling.de/',
  'flight': 'https://nurflug.schmetterling.de/',
}
// Order is important
const ibeStepMap: Record<string, string>[] = [
  { name: '&aid', value: 'offer' },
  { name: '&cyid', value: 'hotel' },
  { name: '&rid', value: 'hotel' },
  { name: '&rgid', value: 'region' },
]

const ibeStep = (ibeStepMap.find(x => props.params?.includes(x.name)))?.value ?? 'region'
const partnerId = 'taid=142035'
const customParams = 'srtHot=3&srtReg=12'
  + (props.params?.includes('dur') ? '' : '&dur=7,14')
  // + (props.ibeParams?.includes('ddate') ? '' : '&ddate=7_tage')
  + (props.params?.includes('rdate') ? '' : '&rdate=120_days')
  + (props.params?.includes('adult') ? '' : '&adult=2')
  + (props.params?.includes('trans') ? '' : '&trans=1')
const ibeUrl = ibeUrlMap[props.ibe]
const srcUrl = [
  ibeUrl + ibeStep + '?ibe=' + props.ibe,
  partnerId,
  customParams,
  props.params,
].filter(x => x !== undefined && x !== null && x !== '').join('&')

useHead({
  // meta: [
  //   {
  //     "http-equiv": "content-security-policy",
  //     content: "default-src *; script-src * 'unsafe-inline' 'unsafe-eval'; style-src 'self' 'unsafe-inline'; img-src * data:; frame-src * paypages.payment.amadeus.com"
  //   }
  // ],
  script: [
    {
      innerHTML: `
        window.addEventListener("message", function (event) {
          if (event.origin === undefined) { return }
          if (event.data.event === "iberesized") { this.window.document.getElementById("tt-container").style.height = event.data.height + 40 + "px" }
        }, false)
      `,
      tagPosition: 'bodyClose',
      async: true,
    },
  ],
})
</script>
